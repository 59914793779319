import type { FC } from "react";
import React from "react";
import translate from "src/lib/translate";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import DailyReadings from "../../DailyReadings/DailyReadings";
import { AppQueryPopupsController } from "../../AppQueryPopups/AppQueryPopupsBloc";

const DailyReadingsDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  return (
    <InDialog
      popup
      title={translate("dailyReadings.title")}
      returnUrl={returnUrl}
      onClose={onClose}
      backdropDismiss={false}
      width="min(calc(100vw - 2rem), 900px)"
      height="min(calc(100vh - 2rem), 1000px)"
    >
      <DailyReadings />
    </InDialog>
  );
};

export default DailyReadingsDialog;
