import styled from "@emotion/styled";
import { useBloc } from "@blac/react";
import React, { memo, useEffect, useMemo } from "react";
import DailyReadingsBloc from "./DailyReadingsBloc";
import ProgressIndicator from "./ProgressIndicator";
import ResponsiveImage, {
  imageFromSrc
} from "../ResponsiveImage/ResponsiveImage";
import CalendarProgress from "./CalendarProgress";
import Translate from "../Translate/Translate";
import { userPreferences } from "src/state/state";
import CompletedWeekBadge from "./CompletedWeekBadge";
import Loader from "../Loader/Loader";
import FadeWrap from "../FadeWrap/FadeWrap";
import ConfettiAnimation from "./ConfettiAnimation";
import FaqComponent from "src/ui/components/FaqComponent/FaqComponent";
import { TranslationKey } from "src/types/translationKey";

const HEADER_IMAGE =
  "https://cdn.sanity.io/images/g38rxyoc/production/309898a6bfab448ea1be434f60a59a0c95b0a6ef-654x352.png";

const ContainerWrap = styled.div`
  position: relative;
  max-width: 447px;
  margin: 0 auto;
  .header-image {
    margin-bottom: -6.1rem;
    position: relative;
    z-index: 1;
  }

  .content-inner {
    position: relative;
    z-index: 2;
  }

  .responsive-image {
    border-radius: 0.5rem 0.5rem 0 0;
    pointer-events: none;
  }

  .confetti-animation {
    position: absolute;
    top: -1.6rem;
    left: 0;
    right: 0;
    z-index: 100;
    transform: scale(1.3);
    transform-origin: top center;
  }
`;

const SpaceForBadge = styled.div`
  width: 100%;
  padding: 1.2rem 0 0.5rem;
  display: grid;
  place-items: center;

  .completed-week-badge {
    max-width: 184px;
  }
`;

const Title = styled.h1`
  color: var(--color-charcoal);
  font-size: 2.2rem;
  font-weight: 300;
  line-height: 1.2;
  margin-top: 1.8rem;
  text-align: center;
  text-wrap: balance;
  padding: 0 1rem;
`;

const Description = styled.h2`
  color: var(--color-charcoal-80);
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  text-wrap: balance;
  font-family: var(--font-family);
`;

const Gap = styled.hr`
  margin: 2.5rem 0;
  border: 0;
  border-bottom: 1px solid var(--Greys-Light-Gray, #e6e3db);
`;

const DailyReadings = memo(() => {
  const [
    state,
    { loadData, listOfDatesDisplay, today, completedWeek, dataLoaded }
  ] = useBloc(DailyReadingsBloc);

  useEffect(() => {
    void loadData();
  }, [loadData]);

  const translationVariables = {
    firstName: userPreferences.firstName
  };

  const faqItems = useMemo(
    () =>
      Array.from({ length: 5 }, (_, index) => ({
        question: `faq.dailyReadings.question_${index + 1}` as TranslationKey,
        answer: `faq.dailyReadings.answer_${index + 1}` as TranslationKey
      })),
    []
  );

  if (state.loading && !dataLoaded) return <Loader grow active />;

  return (
    <FadeWrap>
      <ContainerWrap>
        {!completedWeek && (
          <ResponsiveImage
            className="header-image"
            image={imageFromSrc(HEADER_IMAGE)}
          />
        )}
        {completedWeek && (
          <SpaceForBadge>
            <ConfettiAnimation
              className="confetti-animation"
              playTimeout={1000}
            />
            <CompletedWeekBadge className="completed-week-badge" />
          </SpaceForBadge>
        )}
        <div className="content-inner">
          {!completedWeek && <ProgressIndicator count={state.logsThisWeek} />}
          <Title>
            <Translate msg={state.title} variables={translationVariables} />
          </Title>
          <CalendarProgress
            missed={state.datesWithMissed}
            readings={state.datesWithReadings}
            dates={listOfDatesDisplay}
            today={today}
          />
          <Description>
            <Translate
              msg={state.description}
              variables={translationVariables}
            />
          </Description>
          <Gap />
          <FaqComponent title="faq.dailyReadings.title" faqItems={faqItems} />
        </div>
      </ContainerWrap>
    </FadeWrap>
  );
});

export default DailyReadings;
